import { styled } from 'styled-components';
import colors from '../../styles/colors';

interface CenterProps {
  mobileView: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  overflow: scroll;
`;

export const CenterDiv = styled.div<CenterProps>`
  width: ${(props) => (props.mobileView ? '100%' : '50%')};
  height: 100%;
  display: flex;
  justify-content: center;

  padding: ${(props) => (props.mobileView ? '20px' : '0')};

  flex-direction: column;

  .padding-upload {
    padding: 16px;
    width: 100%;
  }

  .row-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;

    svg {
      cursor: pointer;
    }
  }
`;

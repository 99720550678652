import { styled } from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px dashed var(--gray-main, ${colors.grayRegular});
  background: ${colors.white};
  width: 100%;
  height: 240px;
  cursor: pointer;
  margin-bottom: 24px;
`;

export const HiddenInput = styled.input`
  display: none;
`;

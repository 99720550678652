import React, { useState } from 'react';
import {
  Heading1Medium,
  Paragraph,
  ParagraphBold,
  SubtitleRegular,
} from '../../styles/text';
import * as Styled from './styles';
import colors from '../../styles/colors';
import { Tooltip } from 'react-tooltip';
import DropZoneUpload from '../../components/DropZone';
import CallToActionButton from '../../components/Button';
import UploadRowItem from '../../components/UploadRowItem';
import useDetectDevice from '../../helpers/device';
import { apiRequester } from '../../api';
import { getBase64 } from '../../helpers/file';
import SuccessPage from '../SuccessUpload';
import bitGetLogo from '../../assets/logos/bitget.png';
import htxLogo from '../../assets/logos/htx.png';
import kucoinLogo from '../../assets/logos/kucoin.png';

const UploadFilesPage = () => {
  const detect = useDetectDevice();

  const [fileList, setFileList] = useState<File[]>([]);
  const [sendingFiles, setSendingFiles] = useState(false);
  const [successUpload, setSuccessUpload] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const uuidByParams = window.location.search.split('=')[1];

  const getCompFromUrl = window.location.href.split('/')[3]; // get the logo and change
  const getUUIDFromUrl = window.location.href.split('/')[4];

  // userId para testar: 94f994d9-a631-4d55-ad08-288ad59c9245

  const getLogoByExchange = () => {
    switch (getCompFromUrl) {
      case 'bitget':
        return bitGetLogo;
      case 'htx':
        return htxLogo;
      case 'kucoin':
        return kucoinLogo;
      default:
        return '';
    }
  };

  const errorsList = [
    {
      code: 'E_USER_NOT_FOUND',
      msg: 'user not found',
    },
    { code: 'E_USER_IN_ANALYSIS', msg: 'docs already under analysis' },
    { code: 'E_MAX_5_DOCS', msg: 'only five files' },
    { code: 'E_MAX_5MB', msg: 'max 5MB per file' },
  ];

  const uploadFiles = async () => {
    if (uuidByParams) return;

    setSendingFiles(true);

    const fileListBase64 = await Promise.all(
      fileList.map(async (file) => {
        return await getBase64(file);
      })
    );

    try {
      const apiResp = await apiRequester.post(`/compliance/${getUUIDFromUrl}`, {
        docs: fileListBase64,
      });

      if (apiResp.data?.error) {
        const erroMsg = errorsList.find(
          (error) => error.code === apiResp.data.error
        );

        setErrorMsg(erroMsg?.msg || 'Unknown error');
      } else {
        setSuccessUpload(true);
      }
    } catch (error) {
      console.log(error);
    }

    setSendingFiles(false);
  };

  const removeFile = (file: File) => {
    const newList = fileList.filter((item) => item !== file);
    setFileList(newList);
  };

  return (
    <>
      {!successUpload ? (
        <>
          <Styled.Container>
            <Styled.CenterDiv mobileView={detect.isMobile()}>
              {getLogoByExchange() !== '' && (
                <img
                  style={{
                    width: 122,
                    height: 32,
                    marginTop: 48,
                    objectFit: 'contain',
                  }}
                  alt='logo'
                  src={getLogoByExchange()}
                />
              )}

              <Heading1Medium marginTop={32} marginBottom={24}>
                Additional Document Verification
              </Heading1Medium>
              <Paragraph marginBottom={16}>
                To continue using our services, please upload the following
                documents:
              </Paragraph>
              <div className="row-card">
                <SubtitleRegular color={colors.grayMedium}>
                  Proof of Financial Capability
                </SubtitleRegular>

                <div
                  data-tooltip-id="info-details"
                  data-tooltip-html="Accepted documents include Income Tax Return,<br/> Income Statement, Payroll, DECORE, and Deed of Gift of<br/> Assets and Values (if applicable). For corporate entities,<br/> attach the Balance Sheet from the last fiscal year."
                >
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16 24C16 19.5817 19.5817 16 24 16C28.4183 16 32 19.5817 32 24C32 28.4183 28.4183 32 24 32C19.5817 32 16 28.4183 16 24ZM24 14C18.4772 14 14 18.4772 14 24C14 29.5228 18.4772 34 24 34C29.5228 34 34 29.5228 34 24C34 18.4772 29.5228 14 24 14ZM23 20.5C23 19.9477 23.4477 19.5 24 19.5C24.5523 19.5 25 19.9477 25 20.5C25 21.0523 24.5523 21.5 24 21.5C23.4477 21.5 23 21.0523 23 20.5ZM25 23V24V28V29H23V28V24V23H25Z"
                      fill="#272937"
                    />
                  </svg>
                </div>
              </div>
              <DropZoneUpload fileList={fileList} setFileList={setFileList} />

              {fileList.map((file) => {
                const fileSizeToMb = file.size / 1000000;
                const shortFileName =
                  file.name.length > 20
                    ? file.name.substring(0, 15) + '...'
                    : file.name;
                return (
                  <UploadRowItem
                    fileName={shortFileName}
                    fileSize={fileSizeToMb.toFixed(2).toString()}
                    onRemove={() => {
                      removeFile(file);
                    }}
                    key={file.name}
                  />
                );
              })}

              {errorMsg && (
                <ParagraphBold color={colors.danger}>
                  Error: {errorMsg}
                </ParagraphBold>
              )}

              <div className="padding-upload">
                <CallToActionButton
                  size="large"
                  fullWidth
                  label="Send Documents"
                  backgroundColor={colors.black}
                  onClick={uploadFiles}
                  disabled={fileList.length === 0}
                  loading={sendingFiles}
                />
              </div>
            </Styled.CenterDiv>
          </Styled.Container>

          <Tooltip
            id="info-details"
            place="top"
            style={{
              backgroundColor: colors.black,
              opacity: 1,
              borderRadius: 8,
              padding: 12,
              gap: 8,
              zIndex: 9999,
            }}
          />
        </>
      ) : (
        <SuccessPage />
      )}
    </>
  );
};

export default UploadFilesPage;

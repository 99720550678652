import axios from 'axios';

// check if url includes 'localhost' or 'capstg' to determine if it's a production or staging environment
const API_BASE_URL = window.location.href.includes('localhost') || window.location.href.includes('capstg')
  ? 'https://settlehelper.capstg.dev/api/v1.0'
  : 'https://settle.capitual.net/api/v1.0';

export const apiRequester = axios.create({
  baseURL: API_BASE_URL,
});

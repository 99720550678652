import { styled } from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${colors.gray};



  .row-gap{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 11px;
  }

  .left-part {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

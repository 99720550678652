import { CallToActionButtonProps } from './types';
import * as S from './styles';
import { Subtitle2Medium } from '../../styles/text';
import colors from '../../styles/colors';


const CallToActionButton = (props: CallToActionButtonProps) => {
  const {
    label,
    onClick,
    disabled,
    fullWidth,
    backgroundColor = colors.primary,
    size,
    color = colors.white,
    leftIcon,
    rightIcon,
    marginColor,
    style,
    loading,
  } = props;

  const noLabel = !label || label?.length <= 0;

  return (
    <S.Button
      onClick={disabled ? () => {} : onClick}
      disabled={disabled}
      fullwidth={fullWidth}
      backgroundcolor={backgroundColor}
      margincolor={marginColor}
      nolabel={noLabel}
      size={size}
      style={style}
    >
      {leftIcon && leftIcon}
      <Subtitle2Medium color={color}>{!loading ? label : 'Sending...'}</Subtitle2Medium>
      {rightIcon && rightIcon}
    </S.Button>
  );
};

export default CallToActionButton;

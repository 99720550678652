const colors = {
  transparent: 'transparent',

  white: '#ffffff',
  whiteLight: '#f8f9fb',
  whiteOpacity50p: 'rgba(248, 249, 251, 0.5)',

  gray: '#f3f4f9',
  grayLight: '#c3c6db',
  grayRegular: '#9ea1b9',
  grayMedium: '#757893',
  mainGray: '#f3f4f9',
  grayOpacity20p: 'rgba(158, 161, 185, 0.2)',

  newBlue: '#026FF4',
  blueViolet: '#ECDDFF',
  blueOpacity10p: 'rgba(2, 111, 244, 0.1)',

  primary: '#026FF4',
  primaryDark: '#0264DC',
  warning: '#f3bA2f',
  warningDark: '#b08e39',
  danger: '#ff6378',
  success: '#00e699',

  redMatte: '#C85B69',
  greenMatte: '#3AAC86',
  primaryOpacity10p: 'rgba(2, 111, 244, 0.1)',
  primaryOpacity20p: 'rgba(2, 111, 244, 0.2)',

  black: '#272937',
  naturalBlack: '#000000',
  naturalBlackOpacity5p: 'rgba(0, 0, 0, 0.05)',
  blackOpacity10p: 'rgba(39, 41, 55, 0.1)',
  blackOpacity50p: 'rgba(39, 41, 55, 0.5)',
};

export default colors;

import React, { useCallback } from 'react';
import * as Styled from './styles';
import { Body2Regular } from '../../styles/text';
import { useDropzone } from 'react-dropzone';
import CallToActionButton from '../Button';
import colors from '../../styles/colors';

const DropZoneUpload = ({
  fileList,
  setFileList,
}: {
  fileList: File[];
  setFileList: React.Dispatch<React.SetStateAction<File[]>>;
}) => {
  const onDrop = useCallback(
    (files: File[]) => {
      setFileList((prevFileList) => [...prevFileList, ...files]);
    },
    [setFileList]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg', '.bmp'],
      'application/pdf': ['.pdf'],
    },
    maxFiles: 5,
    maxSize: 5000000, // 5MB
    multiple: true,
  });

  return (
    <Styled.Container {...getRootProps()}>
      {fileList.length < 5 ? (
        <>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14 20.8285L15.4142 19.4143L22 12.8285L22 30.0001L22 32.0001H26V30.0001L26 12.8279L32.5859 19.4127L34.0002 20.8268L36.8284 17.9981L35.4141 16.584L25.4141 6.58572L23.9999 5.17175L22.5858 6.58584L12.5858 16.5858L11.1716 18.0001L14 20.8285ZM6 28.0001V40.0001V42.0001H8H40H42V40.0001V28.0001H38V38.0001H10V28.0001H6Z"
              fill="#9EA1B9"
            />
          </svg>

          <Body2Regular marginBottom={16} marginTop={16}>
            Drag and drop to upload a document or
          </Body2Regular>

          <CallToActionButton
            size="small"
            color={colors.black}
            label="Browse Files"
            backgroundColor={colors.mainGray}
            onClick={() => {}}
          />
        </>
      ) : (
        <Body2Regular marginBottom={16} marginTop={16}>
          Max 5 files, remove some file to add more
        </Body2Regular>
      )}

      <Styled.HiddenInput {...getInputProps()} />
    </Styled.Container>
  );
};

export default DropZoneUpload;

import styled from 'styled-components';
import colors from './colors';
import { GeneralStyleProps } from '../@types/general';

const baseHeading = styled.p<GeneralStyleProps>`
  display: ${(props) => props.display || 'flex'};
  align-self: ${(props) => props.alignSelf || 'auto'};
  justify-self: ${(props) => props.justifySelf || 'auto'};
  text-align: ${(props) => props.textAlign || 'auto'};
  width: ${(props) => props.width || 'auto'};
  margin: ${(props) => props.marginTop || 0}px
    ${(props) => props.marginRight || 0}px
    ${(props) => props.marginBottom || 0}px
    ${(props) => props.marginLeft || 0}px;
  color: ${(props) => props.color || colors.black};
  letter-spacing: ${(props) => props.letterSpacing || 0.4}px;
  -webkit-font-smoothing: antialiased !important;
`;

export const Heading1Regular = styled(baseHeading)`
  font-family: 'Aeonik Regular', sans-serif;
  font-size: ${(props) => props.fontSize || 1.94}rem;
  line-height: ${(props) => props.lineHeight || 130}%;
  font-weight: 400;
`;

export const Heading1Medium = styled(baseHeading)`
  font-family: 'Aeonik Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 1.94}rem;
  line-height: ${(props) => props.lineHeight || 130}%;
  font-weight: 500;
`;

export const Heading1Bold = styled(baseHeading)`
  font-family: 'Aeonik Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 1.94}rem;
  line-height: ${(props) => props.lineHeight || 130}%;
`;

export const Heading2Regular = styled(baseHeading)`
  font-family: 'Aeonik Regular', sans-serif;
  font-size: ${(props) => props.fontSize || 1.81}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
  font-weight: 400;
`;

export const Heading2Medium = styled(baseHeading)`
  font-family: 'Aeonik Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 1.81}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
  font-weight: 500;
`;

export const Heading2Bold = styled(baseHeading)`
  font-family: 'Aeonik Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 1.81}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Heading3Regular = styled(baseHeading)`
  font-family: 'Aeonik Regular', sans-serif;
  font-size: ${(props) => props.fontSize || 1.63}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
  font-weight: 400;
`;

export const Heading3Medium = styled(baseHeading)`
  font-family: 'Aeonik Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 1.63}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
  font-weight: 500;
`;

export const Heading3Bold = styled(baseHeading)`
  font-family: 'Aeonik Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 1.63}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Heading4Regular = styled(baseHeading)`
  font-family: 'Aeonik Regular', sans-serif;
  font-size: ${(props) => props.fontSize || 1.38}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
  font-weight: 400;
`;

export const Heading4Medium = styled(baseHeading)`
  font-family: 'Aeonik Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 1.38}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
  font-weight: 500;
`;

export const Heading4Bold = styled(baseHeading)`
  font-family: 'Aeonik Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 1.38}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Heading5Regular = styled(baseHeading)`
  font-family: 'Aeonik Regular', sans-serif;
  font-size: ${(props) => props.fontSize || 1.19}rem;
  line-height: ${(props) => props.lineHeight || 130}%;
  font-weight: 400;
`;

export const Heading5Medium = styled(baseHeading)`
  font-family: 'Aeonik Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 1.19}rem;
  line-height: ${(props) => props.lineHeight || 130}%;
  font-weight: 500;
`;

export const Heading5Bold = styled(baseHeading)`
  font-family: 'Aeonik Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 1.19}rem;
  line-height: ${(props) => props.lineHeight || 130}%;
`;

export const SubtitleRegular = styled(baseHeading)`
  font-family: 'Aeonik Regular', sans-serif;
  font-size: ${(props) => props.fontSize || 1.06}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
  font-weight: 400;
`;

export const SubtitleMedium = styled(baseHeading)`
  font-family: 'Aeonik Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 1.06}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
  font-weight: 500;
`;

export const SubtitleBold = styled(baseHeading)`
  font-family: 'Aeonik Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 1.06}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
`;

export const Subtitle2Regular = styled(baseHeading)`
  font-family: 'Aeonik Regular', sans-serif;
  font-size: ${(props) => props.fontSize || 0.9375}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
  font-weight: 400;
`;

export const Subtitle2Medium = styled(baseHeading)`
  font-family: 'Aeonik Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 0.9375}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
  font-weight: 500;
`;

export const Subtitle2Bold = styled(baseHeading)`
  font-family: 'Aeonik Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 0.9375}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
`;

export const BodyRegular = styled(baseHeading)`
  font-family: 'Aeonik Regular', sans-serif;
  font-size: ${(props) => props.fontSize || 1.06}rem;
  line-height: ${(props) => props.lineHeight || 145}%;
  font-weight: 400;
`;

export const BodyMedium = styled(baseHeading)`
  font-family: 'Aeonik Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 1.06}rem;
  line-height: ${(props) => props.lineHeight || 145}%;
  font-weight: 500;
`;

export const BodyBold = styled(baseHeading)`
  font-family: 'Aeonik Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 1.06}rem;
  line-height: ${(props) => props.lineHeight || 145}%;
`;

export const Body2Regular = styled(baseHeading)`
  font-family: 'Aeonik Regular', sans-serif;
  font-size: ${(props) => props.fontSize || 0.9375}rem;
  line-height: ${(props) => props.lineHeight || 145}%;
  font-weight: 400;
`;

export const Body2Medium = styled(baseHeading)`
  font-family: 'Aeonik Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 0.9375}rem;
  line-height: ${(props) => props.lineHeight || 145}%;
  font-weight: 500;
`;

export const Body2Bold = styled(baseHeading)`
  font-family: 'Aeonik Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 0.9375}rem;
  line-height: ${(props) => props.lineHeight || 145}%;
`;

export const Body3Regular = styled(baseHeading)`
  font-family: 'Aeonik Regular', sans-serif;
  font-size: ${(props) => props.fontSize || 0.875}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
  font-weight: 400;
`;

export const Body3Medium = styled(baseHeading)`
  font-family: 'Aeonik Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 0.875}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
  font-weight: 500;
`;

export const Body3Bold = styled(baseHeading)`
  font-family: 'Aeonik Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 0.875}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
`;

export const Paragraph = styled(baseHeading)`
  font-family: 'Helvetica Now Display Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 0.875}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
`;

export const ParagraphBold = styled(baseHeading)`
  font-family: 'Helvetica Now Display Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 0.875}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
`;

export const SmallRegular = styled(baseHeading)`
  font-family: 'Aeonik Regular', sans-serif;
  font-size: ${(props) => props.fontSize || 0.8125}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
  font-weight: 400;
`;

export const SmallMedium = styled(baseHeading)`
  font-family: 'Aeonik Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 0.8125}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
  font-weight: 500;
`;

export const SmallBold = styled(baseHeading)`
  font-family: 'Aeonik Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 0.8125}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const SmallerRegular = styled(baseHeading)`
  font-family: 'Aeonik Regular', sans-serif;
  font-size: ${(props) => props.fontSize || 0.75}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
  font-weight: 400;
`;

export const SmallerMedium = styled(baseHeading)`
  font-family: 'Aeonik Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 0.75}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
  font-weight: 500;
`;

export const SmallerBold = styled(baseHeading)`
  font-family: 'Aeonik Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 0.75}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
`;

import React from 'react';
import GlobalStyle from './styles/global';

import './styles/fonts.css';

import { AppRouters } from './routes/routes';

function App() {
  return (
    <>
      <GlobalStyle />
      <AppRouters />
    </>
  );
}

export default App;

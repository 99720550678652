import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';
import UploadFilesPage from '../pages/UploadFiles';

export const AppRouters = () => {


  return (
    <AnimatePresence mode="wait">
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<UploadFilesPage />} />
        </Routes>
      </BrowserRouter>
    </AnimatePresence>
  );
};

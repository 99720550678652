import React from 'react';
import useDetectDevice from '../../helpers/device';
import * as Styled from './styles';
import { BodyRegular, Heading1Medium } from '../../styles/text';
import CallToActionButton from '../../components/Button';
import colors from '../../styles/colors';

const SuccessPage = () => {
  const detect = useDetectDevice();

  return (
    <Styled.Container>
      <Styled.CenterDiv mobileView={detect.isMobile()}>
        <Heading1Medium marginTop={32} marginBottom={24}>
          Documents Received
        </Heading1Medium>

        <BodyRegular>
          Your documents have been successfully submitted for review. Thank you
          for your cooperation! Our team will review the documents shortly, and
          you will be notified of the status of your verification.
        </BodyRegular>

        <BodyRegular marginTop={24}>
          In the meantime, feel free to explore our services and stay tuned for
          updates in your inbox.
        </BodyRegular>

        <BodyRegular marginBottom={40} marginTop={24}>Questions? We're here to help.</BodyRegular>

        <CallToActionButton
          size="small"
          color={colors.black}
          label="Customer Support"
          backgroundColor={colors.mainGray}
          onClick={() => {
            window.open('https://help.capitual.com/pt/contact/', '_blank');
          }}
        />
      </Styled.CenterDiv>
    </Styled.Container>
  );
};

export default SuccessPage;

import React from 'react';
import * as Styled from './styles';
import { Body3Regular, SubtitleBold } from '../../styles/text';
import CallToActionButton from '../Button';
import colors from '../../styles/colors';

const UploadRowItem = ({
  fileName,
  fileSize,
  onRemove,
}: {
  fileName: string;
  fileSize: string;
  onRemove: () => void;
}) => {


  const fileExtension = fileName?.split('.')?.pop();

  return (
    <Styled.Container>
      <div className='row-gap'>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.002 3.00244C5.34515 3.00244 4.00201 4.34558 4.002 6.00243L4.00195 18.0024C4.00195 19.6593 5.34509 21.0024 7.00195 21.0024L16.9587 21.0024C18.61 21.0024 19.9508 19.668 19.9587 18.0168L20.0019 9.00724V9.00244V8.00244C20.0019 7.73722 19.8966 7.48287 19.7091 7.29533L15.7091 3.29533C15.5215 3.1078 15.2672 3.00244 15.0019 3.00244H14.0019H7.002ZM6.002 6.00244C6.002 5.45015 6.44972 5.00244 7.002 5.00244H13.0019V9.00244C13.0019 9.55473 13.4497 10.0024 14.0019 10.0024H17.9971L17.9587 18.0072C17.9561 18.5576 17.5092 19.0024 16.9587 19.0024L7.00195 19.0024C6.44967 19.0024 6.00195 18.5547 6.00195 18.0024L6.002 6.00244ZM15.0019 8.00244V5.41665L17.5877 8.00244H15.0019ZM8.00195 13.0024H16.0019V11.0024H8.00195V13.0024ZM11.0019 9.00244H8.00195V7.00244H11.0019V9.00244ZM8.00195 17.0024H16.0019V15.0024H8.00195V17.0024Z"
            fill="#272937"
          />
        </svg>

        <div className="left-part ">
       
          <SubtitleBold>{fileName}</SubtitleBold>
          <Body3Regular> .{fileExtension} {fileSize} MB</Body3Regular>
        </div>
      </div>
      <div>
        <CallToActionButton
          size="small"
          color={colors.black}
          label="Remove"
          backgroundColor={colors.mainGray}
          onClick={onRemove}
        />
      </div>
    </Styled.Container>
  );
};

export default UploadRowItem;
